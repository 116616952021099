// import React, { useEffect } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import db from "../Firebase";

// const FailurePage = () => {
//   const location = useLocation();
//   const query = new URLSearchParams(location.search);
//   const data = JSON.parse(query.get('data') || '{}');
//   const amount = data.amount; // Extract the amount from the data object
//   const customerId = data.customer_id; // Ensure the customer_id is part of the data object


//   return (
//     <div style={{ marginTop: '10rem' }}>
//       <div className="text-center">
//         <h2>Your payment is failed</h2>
//         <p>Please try again</p>
//         <br />
//         <Link to="/" className="subscribe-button">
//           <span style={{ color: 'black', fontWeight: 'bold' }}>Go To Dashbaord</span>
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default FailurePage;



// import React from 'react';
// import { useLocation } from 'react-router-dom';

// const FailurePage = () => {
//   const location = useLocation();
//   const query = new URLSearchParams(location.search);

//   let data = null;
//   try {
//     const dataString = query.get('data');
//     if (dataString) {
//       data = JSON.parse(dataString);
//     }
//   } catch (e) {
//     console.error('Failed to parse data:', e);
//   }

//   console.log("Failure page data:", data);

//   return (
//     <div>
//       <h2>Payment Failed</h2>
//       <p>Unfortunately, your payment could not be processed. Please try again.</p>
//       <pre>{JSON.stringify(data, null, 2)}</pre>
//     </div>
//   );
// };

// export default FailurePage;



// import React, { useEffect, useState } from 'react';

// const FailurePage = () => {
//   const [failureData, setFailureData] = useState(null);

//   useEffect(() => {
//     // Parse the data from query parameter 'data'
//     const urlParams = new URLSearchParams(window.location.search);
//     const dataParam = urlParams.get('data');
//     if (dataParam) {
//       // Decode and parse JSON data
//       const decodedData = decodeURIComponent(dataParam);
//       const parsedData = JSON.parse(decodedData);
//       setFailureData(parsedData);
//     }
//   }, []);

//   return (
//     <div>
//       <h1>Payment Failure</h1>
//       {failureData && (
//         <div>
//           <p>Transaction ID: {failureData.txnid}</p>
//           <p>Status: {failureData.status}</p>
//           <p>Error Message: {failureData.error_Message}</p>
//           {/* Add more fields as needed */}
//         </div>
//       )}
//     </div>
//   );
// };

// export default FailurePage;

import React from 'react';
import { useLocation } from 'react-router-dom';

const FailurePage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const data = JSON.parse(decodeURIComponent(params.get('data') || '{}'));

  return (
    <div>
      <h1>Payment Failure</h1>
      <p>{data.error_Message}</p>
      {/* Display other relevant data */}
    </div>
  );
};

export default FailurePage;





