import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import "./Product.css";
import db from "../Firebase";
import { useNavigate, Link } from "react-router-dom";

const Product = () => {
    const [product, setProduct] = useState(null);
    const [data, setData] = useState([]);
    const [cart, setCart] = useState([]);
    const [customerData, setCustomerData] = useState({});
    const [totalOrderValue, setTotalOrderValue] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = db
          .collection("customers_data")
          .doc("customer_id")
          .onSnapshot((snapshot) => {
            setCustomerData(snapshot.data());
          });
    
        return () => unsubscribe();
      }, []);

    useEffect(() => {
        const orderItems = [];
        const orderTotal = orderItems.reduce(
          (total, item) => total + item.price,
          0
        );
        setTotalOrderValue(orderTotal);
    }, []);

    useEffect(() => {
        console.log("Fetching product data...");
        const unsubscribe = db
          .collection("products_data")
          .orderBy("created_date", "desc")
          .onSnapshot((snapshot) => {
            setData(
              snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
            );
          });
        console.log("Loading cart data from localStorage...");
        const savedCart = localStorage.getItem("cart");
        if (savedCart) {
          console.log("Cart data found:", JSON.parse(savedCart));
          setCart(JSON.parse(savedCart));
        } else {
          console.log("No cart data found in localStorage.");
        }
    
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (cart.length > 0) {
          localStorage.setItem("cart", JSON.stringify(cart));
        }
    }, [cart]);

    useEffect(() => {
        console.log("Fetching cart data from localStorage...");
        const savedCart = localStorage.getItem("cart");
        if (savedCart) {
          console.log("Cart data found:", JSON.parse(savedCart));
          setCart(JSON.parse(savedCart));
        } else {
          console.log("No cart data found in localStorage.");
        }
    }, [data]);

    const handleAddToCart = (product) => {
        setCart([...cart, product]);
    };

    const handleRemoveFromCart = (index) => {
        const updatedCart = [...cart];
        updatedCart.splice(index, 1);
        setCart(updatedCart);
    };

    const gotocart = () => {
        navigate("/cart");
    };

    const addProduct = (id) => {
        navigate(`/product_details/${id}`);
    };

    const buyNow = () => {
        navigate(`/product_details/HNgdBpryadraSsfYv1zh`);
    };

    const bookFarmVisit = () => {
        navigate('/bookyourfarm');
    };
    
    return (
        <>
           <div class="product-banner">
    <div class="white-cow-img">
    <img src="images/white-cow.png"/>
  </div>
  <div>
    <h1 class="heading-story">Our Products</h1></div>
    <div class="story-overlay">
    <Navbar/>
    </div>
   
   </div>

            <div className="third-section-image product-page">
                <div className="container mt-5">
                    <div className="row mt-5">
                        {data.map((item) => (
                            <div key={item.id} className="col-sm-6 col-md-3 col-lg-3 col-xs-6">
                                <div className="card product-item-card">
                                    <img 
                                        src={item.image}
                                        className="card-img-top product-item-image"
                                        alt={item.image}
                                    />
                                    <div className="card-body product-item-name text-center">
                                        <h5>{item.productName}</h5>
                                    </div>
                                    <div className="product-details">
                                        <div className="item-1"><p>1 ltr</p></div>
                                    </div>
                                    <div className="product-price theme-color text-center mt-2">
                                        <p><b>&#8377;{item.price}</b></p>
                                    </div>
                                    <div className="product-view-button text-center mt-2">
                                        <button type="button" className="btn btn-light" onClick={() => addProduct(item.id)}>Add</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Product;
