import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "../Firebase";
import axios from 'axios';

function CreatePaymentLink() {
    let params = useParams();
    const [customerName, setCustomerName] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [amount, setAmount] = useState('');


    useEffect(() => {
        const fetchPayment = async () => {
            try {
                const doc = await db.collection('recharge_link').doc(params.id).get();
                if (doc.exists) {
                    const data = doc.data();
                    setCustomerName(data.name || '');
                    setCustomerPhone(data.phone || '');
                    setCustomerEmail(data.email || '');
                    setAmount(data.amount || '');
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error getting document:', error);
            }
        };

        fetchPayment();
    }, []);




    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            // customerName,
            // customerPhone,
            // customerEmail,
            // amount,
            "amount": `${parseFloat(amount)}`,
            "productInfo": "rechargelink",
            "firstname": `${customerName}`,
            "email": `${customerEmail}`,
            "phone": `${customerPhone}`
        };
        console.log(data);
        try {
            const response = await axios.post('http://whytefarms.in:5000/payment', data);
            const { payUData, payuURL } = response.data;

            const form = document.createElement('form');
            form.action = payuURL;
            form.method = 'POST';

            Object.keys(payUData).forEach((key) => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = payUData[key];
                form.appendChild(input);
            });

            document.body.appendChild(form);
            form.submit();
        } catch (error) {
            console.error('Payment Error:', error);
        }

    };

    return (
        <div className="wrapper">
            <div className="payment">
                <div className="payment-logo">
                    <img src="../images/logo-icon.webp" alt="Logo" />
                </div>

                <h2>Add Amount</h2>
                <div style={{ display: "flex", justifyContent: "end" }}>
                    {/* <p>Wallet Balance: <span style={{ fontSize: "18px", color: "green", fontWeight: "800" }}>500</span></p> */}
                </div>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="card-grp space">
                        <div className="card-item icon-relative" style={{ width: "100%" }}>
                            <label className="label">Customer Name</label>
                            <input type="text" className="input" placeholder="name" value={customerName} onChange={(e) => setCustomerName(e.target.value)} required disabled />
                            {/* <i className="fas fa-user"></i> */}
                        </div>
                    </div>

                    <div className="card-grp space">
                        <div className="card-item icon-relative" style={{ width: "100%" }}>
                            <label className="label">Customer Phone Number</label>
                            <input type="tel" className="input" placeholder="phone" value={customerPhone} onChange={(e) => setCustomerPhone(e.target.value)} required disabled />
                            {/* <i className="fas fa-phone"></i> */}
                        </div>
                    </div>

                    <div className="card-grp space">
                        <div className="card-item icon-relative" style={{ width: "100%" }}>
                            <label className="label">Customer Email</label>
                            <input type="email" className="input" placeholder="email" value={customerEmail} onChange={(e) => setCustomerEmail(e.target.value)} required />
                            {/* <i className="fas fa-envelope"></i> */}
                        </div>
                    </div>

                    <div className="card-grp space">
                        <div className="card-item icon-relative" style={{ width: "100%" }}>
                            <label className="label">Amount</label>
                            <input type="text" className="input" placeholder="000" value={amount} onChange={(e) => setAmount(e.target.value)} required />
                            {/* <i className="fas fa-rupee"></i> */}
                        </div>
                    </div>

                    <div style={{ textAlign: "center" }}>
                        <button style={{ background: "#d2ab67", borderRadius: "50px", padding: "10px 100px", color: "white", height: "3rem" }} className="btn recharge-btn">Recharge</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreatePaymentLink;





// import React, { useState } from 'react';
// import db from "../Firebase";

// function CreatePaymentLink() {
//     const [customerName, setCustomerName] = useState('');
//     const [customerPhone, setCustomerPhone] = useState('');
//     const [customerEmail, setCustomerEmail] = useState('');
//     const [amount, setAmount] = useState('');

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         const rechargeData = {
//             customerName,
//             customerPhone,
//             customerEmail,
//             amount,
//         };

//         console.log(rechargeData)
//     };

//     return (
//         <>
//             <div className="wrapper">
//                 <div className="payment">
//                     <div className="payment-logo">
//                         <img src="../images/logo-icon.webp" alt="Logo" />
//                     </div>

//                     <h2>Add Amount</h2>
//                     <div style={{ display: "flex", justifyContent: "end" }}>
//                         <p>Wallet Balance: <span style={{ fontSize: "18px", color: "green", fontWeight: "800" }}>500</span></p>
//                     </div>
//                     <form className="form" onSubmit={handleSubmit}>
//                         <div className="card-grp space">
//                             <div className="card-item icon-relative" style={{ width: "100%" }}>
//                                 <label className="label">Customer Name</label>
//                                 <input type="text" className="input" placeholder="name" value={customerName} onChange={(e) => setCustomerName(e.target.value)} required />
//                                 <i className="fas fa-user"></i>
//                             </div>
//                         </div>

//                         <div className="card-grp space">
//                             <div className="card-item icon-relative" style={{ width: "100%" }}>
//                                 <label className="label">Customer Phone Number</label>
//                                 <input type="tel" className="input" placeholder="phone" value={customerPhone} onChange={(e) => setCustomerPhone(e.target.value)} required />
//                                 <i className="fas fa-phone"></i>
//                             </div>
//                         </div>

//                         <div className="card-grp space">
//                             <div className="card-item icon-relative" style={{ width: "100%" }}>
//                                 <label className="label">Customer Email</label>
//                                 <input type="email" className="input" placeholder="email" value={customerEmail} onChange={(e) => setCustomerEmail(e.target.value)} required />
//                                 <i className="fas fa-envelope"></i>
//                             </div>
//                         </div>

//                         <div className="card-grp space">
//                             <div className="card-item icon-relative" style={{ width: "100%" }}>
//                                 <label className="label">Amount</label>
//                                 <input type="text" className="input" placeholder="000" value={amount} onChange={(e) => setAmount(e.target.value)} required />
//                                 <i className="fas fa-rupee"></i>
//                             </div>
//                         </div>

//                         {/* <div className="subscribe-button" style={{ textAlign: "center" }}>
//                             <button type="submit">Recharge</button>
//                         </div> */}
//                         <div style={{ textAlign: "center" }}>
//                             <button style={{ background: "#d2ab67", borderRadius: "50px", padding: "10px 100px", color: "white", height: "3rem" }} className="btn recharge-btn">Recharge</button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default CreatePaymentLink;
