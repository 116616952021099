import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import db from "../Firebase";
import "./profile.css";
import Form from "react-bootstrap/Form";
import Cookies from "js-cookie";
import Moment from "moment";
import { extendMoment } from "moment-range";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Navbar from "./Navbar";
import CryptoJS from 'crypto-js';
import Footer from "./Footer";
import Dialog from "./Dialog";
import { collection, getDocs, query, where, getCountFromServer } from 'firebase/firestore';
import CustomerDetails from "./CustomerDetails";

function Profile() {
  const moment = extendMoment(Moment);
  const [loadDeliveryPref, setLoadDelieveryPref] = useState(false);
  const [loadProfile, setLoadProfile] = useState(true);
  const [loadSubs, setLoadSubs] = useState(false);
  const [loadOrders, setLoadOrders] = useState(false);
  const [loadWallet, setLoadWallet] = useState(false);
  const [loadCalendar, setLoadCalendar] = useState(false);
  const [walletHistory, setWalletHistory] = useState([]);
  const [amount, setAmount] = useState("");
  const [email, setEmail] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const navigate = useNavigate();
  const [hubs, setHubs] = useState([]);
  const [locations, setLocations] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [advanceOrders, setAdvanceOrders] = useState([]);
  const [subscriptionCount, setSubscriptionCount] = useState(0);
  const [ordersCount, setOrdersCount] = useState(0);
  const [edit, setEdit] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    id: "", // Add this field to store the document ID
    customer_name: "",
    alt_phone: "",
    flat_villa_no: "",
    floor: "",
    customer_phone: "",
    customer_address: "",
    customer_type: false,
    city: "",
    state: "",
    pincode: "",
    hub_name: "",
    location: "",
    delivery_exe_id: "",
  });

  const [orderHistory, setOrderHistory] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [deliveryExecutive, setDeliveryExecutive] = useState(null);
  const [deliveryPreference, setDeliveryPreference] = useState("");
  const [futureDate, setFutureDate] = useState(new Date());
  const [selectedSub, setSelectedSub] = useState();
  const [newQuantity, setNewQuantity] = useState("");
  const [updateAdvanceOrder, setUpdateAdvanceOrder] = useState(false);
  const [customerVacations, setCustomerVacations] = useState([]);
  const [advancedOrderID, setAdvancedOrderID] = useState({});



  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    //setFutureDate(tomorrow);
    //fetchAdvanceOrders(tomorrow);
    const cookieValue = Cookies.get("user");
    if (cookieValue) {
      const parsedValue = JSON.parse(cookieValue);
      const phoneNumber = parsedValue.phone;

      const fetchCustomerData = async () => {
        try {
          const customersRef = db.collection("customers_data");
          const querySnapshot = await customersRef
            .where("customer_phone", "==", phoneNumber)
            .get();

          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            const data = doc.data();
            setCustomerDetails({ ...data, id: doc.id });
            fetchLocations(data.hub_name);
            fetchDeliveryExecutiveId(data.location);
            fetchSubscriptionCount(data.customer_id);
            fetchOrdersCount(data.customer_id);
            fetchSubscriptions(data.customer_id);
            // fetchWalletHistory(data.customer_id);
            // fetchOrderHistory(data.customer_id);
            //fetchDeliveryPreference(data.customer_id);
            //advOrd(data.customer_id)
            fetchDeliveryExecutive(data.delivery_exe_id);
            //handleFutureDateChange(futureDate)
          } else {
            console.log("No customer data found for this phone number");
          }
        } catch (error) {
          console.error("Error fetching customer data:", error);
        }
      };
      fetchCustomerData();
    } else {
      navigate("/login");
    }
  }, [navigate]);



  const advOrd = (customerId) => {
    setAdvancedOrderID({});
    db.collection("bulk_update_quantity").where("customer_id", "==", customerId).onSnapshot((snapshot) => {
      const aOIDData = snapshot.docs.map(doc => {
        const data = doc.data();

        const { date, product_name, quantity } = data;

        const formattedDate = moment(date.toDate()).format('YYYY-MM-DD');
        const key = `${product_name}-${formattedDate}`;
        return { [key]: quantity };
      });

      // Set the state with the fetched data
      setAdvancedOrderID(Object.assign({}, ...aOIDData));
    });
  };

  const fetchDeliveryPreference = async (customerId) => {
    try {
      const preferenceQuery = await db
        .collection("delivery_preference")
        .where("customer_id", "==", customerId)
        .get();

      if (!preferenceQuery.empty) {
        const preferenceData = preferenceQuery.docs[0].data();
        setDeliveryPreference(preferenceData.delivery_mode);
      }
    } catch (error) {
      console.error("Error fetching delivery preference:", error);
    }
  };

  const fetchDeliveryExecutive = async (id) => {
    try {
      const querySnapshot = await db
        .collection("hubs_users_data")
        .where("hub_user_id", "==", id)
        .get();

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        setDeliveryExecutive(doc.data());
      } else {
        console.error("No such delivery executive!");
      }
    } catch (error) {
      console.error("Error fetching delivery executive: ", error);
    }
  };

  const fetchOrderHistory = async (customerId) => {
    const ordersRef = db
      .collection("order_history")
      .where("customer_id", "==", customerId);
    const snapshot = await ordersRef.get();

    const orders = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setOrderHistory(orders);

    // Extract unique orders by order_id
    const uniqueOrders = orders
      .reduce((acc, current) => {
        const x = acc.find((item) => item.order_id === current.order_id);
        if (!x) {
          return acc.concat([current]);
        }
        return acc;
      }, [])
      .sort((a, b) => b.created_date.seconds - a.created_date.seconds);

    setUniqueOrders(uniqueOrders);
  };

  const handleOrderClick = (order) => {
    const products = orderHistory
      .filter((o) => o.order_id === order.order_id)
      .map((o) => ({
        name: o.product_name,
        quantity: o.quantity,
        package_unit: o.package_unit,
        price: o.price,
        image: o.image, // Make sure this field exists
      }));

    setSelectedOrder({
      ...order,
      products,
    });
  };

  const fetchWalletHistory = async (customerId) => {
    try {
      const walletHistoryRef = db.collection("wallet_history");
      const querySnapshot = await walletHistoryRef
        .where("customer_id", "==", customerId)
        .get();
      const history = querySnapshot.docs.map((doc) => doc.data());
      setWalletHistory(history);
    } catch (error) {
      console.error("Error fetching wallet history:", error);
    }
  };

  const fetchVacations = async (customerId) => {
    try {
      const walletHistoryRef = db.collection("wallet_history");
      const querySnapshot = await walletHistoryRef
        .where("customer_id", "==", customerId)
        .get();
      const vacations = querySnapshot.docs.map((doc) => doc.data());
      setCustomerVacations(vacations);
    } catch (error) {
      console.error("Error fetching wallet history:", error);
    }
  };

  const fetchSubscriptionCount = async (customerId) => {
    try {
      const subscriptionsRef = db.collection("subscriptions_data");
      const querySnapshot = await subscriptionsRef
        .where("customer_id", "==", customerId)
        .get();
      setSubscriptionCount(querySnapshot.size);
    } catch (error) {
      console.error("Error fetching subscription count:", error);
    }
  };




  const fetchSubscriptions = async (customerId) => {
    try {
      const subscriptionsRef = db.collection("subscriptions_data");
      const querySnapshot = await subscriptionsRef
        .where("customer_id", "==", customerId)
        .get();
      const subscriptionsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSubscriptions(subscriptionsList);
      console.log("total subs : ", subscriptionsList.length);
      //fetchAdvanceOrders(futureDate)
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  const isCustomerOnVacation = async (date) => {
    debugger;
    date.setHours(0, 0, 0, 0);
    const endDate = new Date(date);
    endDate.setHours(23, 59, 59, 999);



    try {
      const collRef = collection(db, 'customers_vacation');
      const q = query(collRef, where('customer_id', '==', customerDetails.customer_id)
        , where('start_date', '<=', endDate)
        , where('end_date', '>=', date))

      const snapshot = await getCountFromServer(q);

      if (snapshot.data().count >= 1) {
        return true;
      } else {
        return false;
      }

    } catch (error) {
      console.error("Error fetching collection size: ", error);
    }

    // const vacationSnapshot = await db.collection('customers_vacation')
    // .where('customer_id','==',customerDetails[0].customer_id)
    // .where('start_date' , '<=' , futureDate)
    // .where('end_date' , '>=' , futureDateEnd)



  }


  const calculateScheduledDates = (subscription) => {
    const deliveryDate = new Date(subscription.next_delivery_date);
    deliveryDate.setHours(0, 0, 0);
    const interval = parseInt(subscription.interval);
    const futureCheckDate = new Date(futureDate);
    futureCheckDate.setHours(0, 0, 0);
    if (isNaN(interval) || !deliveryDate || !futureCheckDate) {
      return false;
    }

    let currentDate = new Date(deliveryDate);
    while (currentDate <= futureCheckDate) {
      if (currentDate.getTime() === futureCheckDate.getTime()) {
        return true;
      }
      currentDate.setDate(currentDate.getDate() + interval);
    }
    return false;
  };



  const getBulkQuantity = async (futureDate, subscription) => {
    const futureDateEnd = new Date(futureDate);
    futureDateEnd.setHours(23, 59, 59, 999);

    try {
      const collRef = collection(db, 'bulk_update_quantity');
      const q = query(
        collRef,
        where('subscription_id', '==', subscription.subscription_id),
        where('date', '>=', futureDate),
        where('date', '<=', futureDateEnd)
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        return data.quantity;
      } else {

        return null;
      }
    } catch (error) {
      console.error("Error fetching bulk quantity: ", error);
      //throw error;
    }
  };

  const getBulkQuantityForUpdate = async (futureDate, sid) => {
    const futureDateEnd = new Date(futureDate);
    futureDateEnd.setHours(23, 59, 59, 999);

    try {
      const collRef = collection(db, 'bulk_update_quantity');
      const q = query(
        collRef,
        where('subscription_id', '==', sid),
        where('date', '>=', futureDate),
        where('date', '<=', futureDateEnd)
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0]; // Since you expect only one record
        const data = doc.data();
        return data.quantity;
      } else {

        return null; // or some default value
      }
    } catch (error) {
      console.error("Error fetching bulk quantity: ", error);
      //throw error;
    }
  };

  const fetchAdvanceOrders = async (futureDate) => {

    const subsList = [];
    setAdvanceOrders([]);
    const today = new Date();
    const weekdays = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const isVacationOn = await isCustomerOnVacation(futureDate);
    console.log("Customer on vacation : ", isVacationOn);
    debugger;
    if (!isVacationOn) {
      try {
        for (const subscription of subscriptions) {
          if (subscription.subscription_type === "One Time") {
            const selectedDate = moment(futureDate).format("YYYY-MM-DD");
            if (selectedDate === subscription.next_delivery_date) {
              const getBulk = await getBulkQuantity(futureDate, subscription);
              console.log("Bulk Count", getBulk);
              const updatedSubscription = { ...subscription, quantity: getBulk || subscription.quantity };
              subsList.push(updatedSubscription);
            }
          } else {
            if (subscription.subscription_type !== "Custom") {
              if (
                moment(futureDate).format("YYYY-MM-DD") ===
                subscription.next_delivery_date
              ) {
                const getBulk = await getBulkQuantity(futureDate, subscription);

                const updatedSubscription = { ...subscription, quantity: getBulk || subscription.quantity };

                subsList.push(updatedSubscription);

              } else {
                const checkOrder = calculateScheduledDates(subscription);
                if (checkOrder) {

                  const getBulk = await getBulkQuantity(futureDate, subscription);
                  const updatedSubscription = { ...subscription, quantity: getBulk || subscription.quantity };

                  subsList.push(updatedSubscription);
                }
              }
            } else {
              const dayOfWeekIndex = futureDate.getDay();
              const dayOfWeek = weekdays[dayOfWeekIndex];

              if (
                moment(futureDate).format("YYYY-MM-DD") ===
                subscription.next_delivery_date
              ) {

                const getBulk = await getBulkQuantity(futureDate, subscription);
                const updatedSubscription = { ...subscription, quantity: getBulk || subscription.quantity };

                subsList.push(updatedSubscription);
              } else {
                if (subscription[dayOfWeek] !== 0) {
                  subscription.quantity = subscription[dayOfWeek];
                  const getBulk = await getBulkQuantity(futureDate, subscription);
                  const updatedSubscription = { ...subscription, quantity: getBulk || subscription.quantity };

                  subsList.push(updatedSubscription);
                }
              }
            }
          }
        }

        setAdvanceOrders(subsList);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      }
    }

  };

  const fetchOrdersCount = async (customerId) => {
    try {
      const subscriptionsRef = db.collection("order_history");
      const querySnapshot = await subscriptionsRef
        .where("customer_id", "==", customerId)
        .get();
      setOrdersCount(querySnapshot.size);
    } catch (error) {
      console.error("Error fetching subscription count:", error);
    }
  };

  useEffect(() => {
    const fetchHubs = async () => {
      try {
        const hubCollection = db.collection("hubs_data");
        const hubSnapshot = await hubCollection.get();
        const hubList = hubSnapshot.docs.map((doc) => doc.data().hub_name);
        setHubs(hubList);
      } catch (error) {
        console.error("Error getting hubs:", error);
      }
    };
    fetchHubs();
  }, []);

  const fetchDeliveryExecutiveId = async (location) => {
    try {
      const locationCollection = db.collection("hubs_locations_data");
      const locationSnapshot = await locationCollection
        .where("location", "==", location)
        .get();
      const deliveryExecutiveId =
        locationSnapshot.docs[0]?.data().delivery_exe_id || "";
      setCustomerDetails((prevDetails) => ({
        ...prevDetails,
        delivery_exe_id: deliveryExecutiveId,
      }));
    } catch (error) {
      console.error("Error getting delivery executive id:", error);
    }
  };

  const fetchLocations = async (hub_name) => {
    try {
      const locationCollection = db.collection("hubs_locations_data");
      const locationSnapshot = await locationCollection
        .where("hub_name", "==", hub_name)
        .get();
      const locationList = locationSnapshot.docs.map(
        (doc) => doc.data().location
      );
      setLocations(locationList);
    } catch (error) {
      console.error("Error getting locations:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (name === "hub_name") {
      fetchLocations(value);
    }

    if (name === "location") {
      fetchDeliveryExecutiveId(value);
    }
  };

  const handleSave = async () => {
    try {
      const customersRef = db.collection("customers_data");
      await customersRef.doc(customerDetails.id).set(customerDetails);
      alert("Profile updated successfully");
      setEdit(false);
    } catch (error) {
      console.error("Error saving customer data:", error);
      // alert('Error updating profile');
    }
  };

  const loadscreen = (screenName) => {
    debugger;
    setLoadProfile(false);
    setLoadSubs(false);
    setLoadOrders(false);
    setLoadWallet(false);
    setLoadDelieveryPref(false);
    setLoadCalendar(false);
    //setLoadVacations(false);

    if (screenName == "delivery_preference") {
      setLoadDelieveryPref(true);
      fetchDeliveryPreference(customerDetails.customer_id);
    } else if (screenName == "subscriptions") {
      setLoadSubs(true);
      setShowEditBulkQuantity(false);
      fetchSubscriptions(customerDetails.customer_id);
    } else if (screenName === "orders") {
      setLoadOrders(true);
      fetchOrderHistory(customerDetails.customer_id);
    } else if (screenName === "wallet") {
      setLoadWallet(true);
      fetchWalletHistory(customerDetails.customer_id);
    } else if (screenName === "profile") {
      setLoadProfile(true);
    } else if (screenName === "calendar") {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      setFutureDate(tomorrow);
      fetchAdvanceOrders(tomorrow);
      //advOrd(customerDetails.customer_id);
      setLoadCalendar(true);

    }
  };

  const handleQuickAmountSelect = (amount) => {
    setAmount(amount);
  };

  function generateCustomerId() {
    const now = new Date();
    const timestamp = now.getTime(); // Get the timestamp in milliseconds since January 1, 1970
    const random4Digits = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0"); // Generate a random 4-digit number

    // Take the last 4 digits of the timestamp and concatenate with the random 4-digit number
    const customerId =
      (timestamp % 10000).toString().padStart(4, "0") + random4Digits;

    return customerId;
  }

  const handleAddMoney = async () => {
    const customerId = customerDetails.id;
    const newBalance = customerDetails.wallet_balance + Number(amount);

    if (paymentMethod === "Online") {
      const productinfo = "dairy products";

      const key = 'pCVpYPUj';
      const salt = 'M21EDZ10Hx';
      const txnid = `txn${Date.now()}`;
      const hashString = `${key}|${txnid}|${parseFloat(amount)}|${productinfo}|${customerDetails.customer_name}|${customerDetails.customer_email}|${customerDetails.customer_phone}||||||||||${salt}`;
      const hash = CryptoJS.SHA512(hashString).toString();

      // Create form data for the POST request
      const fields = {
        key: key,
        txnid: txnid,
        amount: parseFloat(amount),
        productinfo: productinfo,
        firstname: customerDetails.customer_name,
        email: customerDetails.customer_email,
        phone: customerDetails.customer_phone,
        hash: hash,
        surl: 'https://localhost:3000/success',
        furl: 'https://localhost:3000/failure',
      };

      try {
        const response = await fetch('https://secure.payu.in/_payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams(fields).toString(),
        });

        if (response.ok) {
          console.log('Payment successful!');
          const responseData = await response.text(); // assuming response is text, adjust accordingly
          console.log('Response from PayU:', responseData);
          // Handle success, e.g., redirect to a success page
        } else {
          console.error('Payment failed.');
          const errorResponse = await response.text(); // assuming response is text, adjust accordingly
          console.log('Error response from PayU:', errorResponse);
          // Handle failure, e.g., show error message to the user
        }
      } catch (error) {
        console.error('Error:', error);
        // Handle network errors or other exceptions
      }
    } else {
      if (!email || !amount || !paymentMethod) {
        alert("Email and amount and payment method are required");
        return;
      } else {
        try {
          const txn = generateCustomerId();
          // Update wallet balance
          const customersRef = db.collection("customers_data").doc(customerId);
          await customersRef.update({ wallet_balance: newBalance });

          // Create wallet history log
          const walletHistoryRef = db.collection("wallet_history");
          await walletHistoryRef.add({
            txn_id: txn,
            amount: Number(amount),
            description: "",
            reason: paymentMethod,
            customer_phone: customerDetails.customer_phone,
            customer_id: customerDetails.customer_id,
            customer_name: customerDetails.customer_name,
            hub_name: customerDetails.hub_name,
            delivery_executive: customerDetails.delivery_exe_id,
            current_wallet_balance: newBalance,
            status: "1",
            type: "Credit",
            source: "Website",
            user: customerDetails.customer_name,
            created_date: new Date(),
          });

          setAmount("");
          setEmail("");
          setPaymentMethod("");

          // Update local state
          setCustomerDetails((prevDetails) => ({
            ...prevDetails,
            wallet_balance: newBalance,
          }));
          setWalletHistory((prevHistory) => [
            ...prevHistory,
            {
              customer_id: customerId,
              date: new Date().toISOString(),
              amount: Number(amount),
              payment_method: paymentMethod,
              type: "credit",
            },
          ]);

          alert("Wallet recharged successfully");
        } catch (error) {
          console.error("Error updating wallet balance:", error);
          alert("Error adding money to wallet");
        }
      }
    }


  };

  const [uniqueOrders, setUniqueOrders] = useState([]);

  const toggleSubscriptionStatus = async (docId, currentStatus) => {
    const newStatus = currentStatus === "1" ? "0" : "1";

    try {
      await db
        .collection("subscriptions_data")
        .doc(docId)
        .update({ status: newStatus });
      // Optionally, update the local state to reflect the change
      setSubscriptions((prevSubscriptions) =>
        prevSubscriptions.map((subscription) =>
          subscription.id === docId
            ? { ...subscription, status: newStatus }
            : subscription
        )
      );
    } catch (error) {
      console.error("Error updating subscription status:", error);
    }
  };

  const handleDeleteSubscription = async (docId) => {
    const confirmDeletion = window.confirm(
      "Are you sure you want to delete this subscription?"
    );
    if (confirmDeletion) {
      try {
        await db.collection("subscriptions_data").doc(docId).delete();
        // Update local state to remove the deleted subscription
        setSubscriptions((prevSubscriptions) =>
          prevSubscriptions.filter((subscription) => subscription.id !== docId)
        );
        alert("Subscription deleted successfully");
      } catch (error) {
        console.error("Error deleting subscription:", error);
        alert("Failed to delete subscription");
      }
    }
  };

  const [editingSubscription, setEditingSubscription] = useState(null);
  const [customer_idBU, setCustomer_idBU] = useState("");
  const [productBU, setProductBU] = useState("");
  const [sidBU, setSidBU] = useState("");
  const [quantity, setQuantity] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [shoWEditSubscription, setShoWEditSubscription] = useState(false);
  const [showEditBulkQuantity, setShowEditBulkQuantity] = useState(false);
  const [loadEditSubModal, setLoadEditSubModal] = useState(false);

  const handleEditClick = (subscription) => {
    setShowEditBulkQuantity(!showEditBulkQuantity)
    setEditingSubscription(subscription);
    setEditingSubscriptionQuantity(null);
    setCustomer_idBU(subscription.customer_id);
    setProductBU(subscription.product_name);
    setSidBU(subscription.subscription_id);
    setQuantity(subscription.quantity);
    setStartDate(moment(subscription.start_date.toDate()).format("YYYY-MM-DD"));
    setEndDate(
      subscription.end_date
        ? moment(subscription.end_date.toDate()).format("YYYY-MM-DD")
        : ""
    );
  };

  const [editingSubscriptionQuantity, setEditingSubscriptionQuantity] =
    useState(null);
  const [subscriptionID, setSubscriptionID] = useState("");

  const updateQuantity = (subscription) => {

    setSelectedSub(subscription);
    setLoadEditSubModal(true);
    setShoWEditSubscription(!shoWEditSubscription)
    setEditingSubscriptionQuantity(subscription);
    setSubscriptionID(subscription.id);
    setQuantity(subscription.quantity);
    setEditingSubscription(null);
    setUpdateAdvanceOrder(true);
  };

  const handleUpdateQuantity = async (event) => {
    event.preventDefault();

    try {
      const customersRef = db
        .collection("subscriptions_data")
        .doc(subscriptionID);
      await customersRef.update({ quantity: Number(quantity) });

      alert("Subscription updated successfully");
      setEditingSubscription(null);
    } catch (error) {
      console.error("Error updating subscription: ", error);
      alert("Error updating subscription");
    }
  };

  const handleSaveUP = async (event) => {
    event.preventDefault();
    try {
      const existingUpdates = await db
        .collection("bulk_update_quantity")
        .where("customer_id", "==", editingSubscription.customer_id)
        .where("startDate", "<=", new Date(endDate))
        .where("endDate", ">=", new Date(startDate))
        .get();

      if (!existingUpdates.empty) {
        alert("Updates for these dates already exist for the same customer.");
        return;
      }

      await db.collection("bulk_update_quantity").add({
        customer_id: customer_idBU,
        date: new Date(),
        product_name: productBU,
        quantity: Number(quantity),
        status: "1",
        subscription_id: sidBU,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      });
      alert("Subscription updated successfully");
      setEditingSubscription(null);
      // Optionally, refresh subscriptions list here
    } catch (error) {
      console.error("Error updating subscription: ", error);
      alert("Error updating subscription");
    }
  };

  const handlePreferenceClick = async (preference) => {
    // const customerId = customerId; // Replace with your actual customer ID
    const createdDate = new Date();

    try {
      // Check if there's already a preference for this customer
      const existingPreferenceQuery = await db
        .collection("delivery_preference")
        .where("customer_id", "==", customerDetails.customer_id)
        .get();

      if (!existingPreferenceQuery.empty) {
        // Update existing preference
        const existingPreferenceDoc = existingPreferenceQuery.docs[0];
        await existingPreferenceDoc.ref.update({
          delivery_mode: preference,
          additional_instruction: "",
          selected_days: [],
          status: "1",
          customer_phone: customerDetails.customer_phone,
          customer_id: customerDetails.customer_id,
          customer_name: customerDetails.customer_name,
          updated_date: new Date(),
        });
        alert("Preference updated successfully");
        setDeliveryPreference(preference);
      } else {
        // Add new preference
        await db.collection("delivery_preference").add({
          delivery_mode: preference,
          additional_instruction: "",
          selected_days: [],
          status: "1",
          customer_phone: customerDetails.customer_phone,
          customer_id: customerDetails.customer_id,
          customer_name: customerDetails.customer_name,
          updated_date: new Date(),
          created_date: new Date(),
        });
        setDeliveryPreference(preference);
        alert("Preference added successfully");
      }
    } catch (error) {
      console.error("Error updating preference: ", error);
    }
  };

  const handleFutureDateChange = (date) => {
    setFutureDate(date);
    fetchAdvanceOrders(date);
  };

  const [quantityBulk, setQuantityBulk] = useState('');
  const [deliveryDateBulk, setDeliveryDateBulk] = useState('');
  const [editingOrder, setEditingOrder] = useState(null);
  const [cid, setCid] = useState('');
  const [sid, setSid] = useState('');
  const [pname, setPname] = useState('');

  const updateQuantityBulk = (order, action) => {
    setCid(order.customer_id)
    setSid(order.subscription_id)
    setPname(order.product_name)
    const newQuantity = action === 'increment' ? order.quantityBulk + 1 : order.quantityBulk - 1;
    setEditingOrder({ ...order, quantityBulk: newQuantity });
  };

  const handleAddEntry = async (e) => {

    e.preventDefault();
    const createdDate = new Date().toISOString();
    const futureDateEnd = new Date(futureDate);
    futureDateEnd.setHours(23, 59, 59, 999);

    try {
      const getBulkQty = await getBulkQuantityForUpdate(futureDate, sid);

      if (getBulkQty == null) {
        // Add a new entry
        await db.collection('bulk_update_quantity').add({
          quantity: quantityBulk,
          date: new Date(futureDate),
          status: "1",
          subscription_id: sid,
          customer_id: cid,
          product_name: pname,
          created_date: new Date(),
          updated_date: new Date()
        });
        alert('Entry added successfully!');
      } else {
        // Update the existing entry
        const collRef = collection(db, 'bulk_update_quantity');
        const q = query(
          collRef,
          where('subscription_id', '==', sid),
          where('date', '>=', futureDate),
          where('date', '<=', futureDateEnd)
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0]; // Assuming only one record matches
          await db.collection('bulk_update_quantity').doc(doc.id).update({
            quantity: quantityBulk,
            date: new Date(futureDate),
            status: "1",
            customer_id: cid,
            product_name: pname,
            updated_date: new Date()
          });
          alert('Entry updated successfully!');
        } else {
          console.log('No matching documents found for update');
        }
      }

      setEditingOrder(null);
      setQuantityBulk('');
      fetchAdvanceOrders(futureDate);
    } catch (error) {
      console.error('There was an error adding/updating the entry!', error);
    }
  };

  const onCloseDialog = () => {
    setLoadEditSubModal(false);
  }

  return (
    <>

      <div class="container-fluid profile-top-section">


        <div class="container" style={{ marginTop: '10rem' }}>
          <Navbar />
          <div class="row mt-5">
            <div class="col-md-4 col-lg-4 col-sm-12">
              <div class="profile-btn theme-color">
                Account Details
                <div class="all-btn-profile">
                  <br />
                  <button
                    class={`subscription-button ${loadProfile ? 'active' : ''}`}
                    onClick={() => loadscreen("profile")}
                  >
                    <div class="left-content">
                      <i class="fas fa-bell"></i>
                      Profile
                    </div>
                  </button>
                  <button
                    class={`subscription-button ${loadSubs ? 'active' : ''}`}
                    onClick={() => loadscreen("subscriptions")}
                  >
                    <div class="left-content">
                      <i class="fas fa-bell"></i>
                      Subscription
                    </div>
                    <div class="badge number-badge">{subscriptionCount}</div>
                  </button>
                  <button
                    class={`subscription-button ${loadWallet ? 'active' : ''}`}
                    onClick={() => loadscreen("wallet")}
                  >
                    <div class="left-content">
                      <i class="fas fa-wallet"></i>
                      WALLET
                    </div>
                    <div class="badge number-badge">
                      {customerDetails.wallet_balance}
                    </div>
                  </button>
                  <button class="subscription-button">
                    <div class="left-content">
                      <i class="fas fa-gift"></i>
                      {CustomerDetails.referral_code}
                    </div>
                  </button>
                  <button
                    class={`subscription-button ${loadOrders ? 'active' : ''}`}
                    onClick={() => loadscreen("orders")}
                  >
                    <div class="left-content">
                      <i class="fas fa-shopping-basket"></i>
                      ORDERS
                    </div>
                    <div class="badge number-badge">{ordersCount}</div>
                  </button>
                  <button
                    class={`subscription-button ${loadCalendar ? 'active' : ''}`}
                    onClick={() => loadscreen("calendar")}
                  >
                    <div class="left-content">
                      <i class="fas fa-shopping-basket"></i>
                      Calendar
                    </div>
                  </button>
                  <button
                    class={`subscription-button ${loadDeliveryPref ? 'active' : ''}`}
                    id="Section5"
                    onClick={() => loadscreen("delivery_preference")}
                  >
                    <div class="left-content">
                      <i class="fas fa-shopping-basket"></i>
                      Delivery Preference
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-8 col-lg-8 col-sm-12 mb-5">
              {loadProfile && (
                <div className="user-profile-section">
                  <div className="user-header">
                    <div className="item-profile theme-color">My Profile</div>
                    <div className="item-save">
                      {edit ? (
                        <>
                          {" "}
                          <button
                            type="button"
                            className="subscribe-button"
                            onClick={handleSave}
                          >
                            Save
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="subscribe-button"
                            onClick={() => setEdit(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="user-image">
                    <img
                      src="images/user.png"
                      style={{ width: "130px" }}
                      alt="User"
                    />
                    <div className="left-content">
                      {/* <i className="fas fa-gift"></i> */}
                      +91 - {customerDetails.customer_phone}
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-3 mt-5 col-md-6">
                      {edit ? (
                        <>
                          {" "}
                          <input
                            type="text"
                            placeholder="Your Full Name *"
                            className="form-control br"
                            name="customer_name"
                            value={customerDetails.customer_name}
                            onChange={handleChange}
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <input
                            type="text"
                            placeholder="Your Full Name *"
                            className="form-control br"
                            name="customer_name"
                            value={customerDetails.customer_name}
                            onChange={handleChange}
                            disabled
                          />
                        </>
                      )}
                    </div>
                    <div className="mb-3 col-md-6 mt-5">
                      {edit ? (
                        <>
                          {" "}
                          <input
                            type="email"
                            placeholder="Email ID *"
                            className="form-control br"
                            name="customer_email"
                            value={customerDetails.customer_email || ""}
                            onChange={handleChange}
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <input
                            type="email"
                            placeholder="Email ID *"
                            className="form-control br"
                            name="customer_email"
                            value={customerDetails.customer_email || ""}
                            onChange={handleChange}
                            disabled
                          />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-3 col-md-6">
                      {edit ? (
                        <>
                          {" "}
                          <input
                            type="text"
                            placeholder="Alternate Number"
                            className="form-control br"
                            name="alt_phone"
                            value={customerDetails.alt_phone}
                            onChange={handleChange}
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <input
                            type="text"
                            placeholder="Alternate Number"
                            className="form-control br"
                            name="alt_phone"
                            value={customerDetails.alt_phone}
                            onChange={handleChange}
                            disabled
                          />
                        </>
                      )}
                    </div>
                    <div className="mb-3 col-md-6">
                      {edit ? (
                        <>
                          <Form.Select
                            name="hub_name"
                            className="form-control"
                            style={{ height: "2.83rem" }}
                            value={customerDetails.hub_name || ""}
                            onChange={handleChange}
                          >
                            <option value="">Select Hub</option>
                            {hubs.map((hub) => (
                              <option key={hub} value={hub}>
                                {hub}
                              </option>
                            ))}
                          </Form.Select>
                        </>
                      ) : (
                        <>
                          <Form.Select
                            name="hub_name"
                            className="form-control"
                            style={{ height: "2.83rem" }}
                            value={customerDetails.hub_name || ""}
                            onChange={handleChange}
                            disabled
                          >
                            <option value="">Select Hub</option>
                            {hubs.map((hub) => (
                              <option key={hub} value={hub}>
                                {hub}
                              </option>
                            ))}
                          </Form.Select>
                        </>
                      )}
                    </div>
                    <div className="mb-3 col-md-6">
                      {edit ? (
                        <>
                          <Form.Select
                            name="location"
                            className="form-control"
                            style={{ height: "2.85rem" }}
                            value={customerDetails.location || ""}
                            onChange={handleChange}
                          >
                            <option value="">Select Location</option>
                            {locations.map((location) => (
                              <option key={location} value={location}>
                                {location}
                              </option>
                            ))}
                          </Form.Select>
                        </>
                      ) : (
                        <>
                          <Form.Select
                            name="location"
                            className="form-control"
                            style={{ height: "2.85rem" }}
                            value={customerDetails.location || ""}
                            onChange={handleChange}
                            disabled
                          >
                            <option value="">Select Location</option>
                            {locations.map((location) => (
                              <option key={location} value={location}>
                                {location}
                              </option>
                            ))}
                          </Form.Select>
                        </>
                      )}
                    </div>
                    <div className="mb-3 col-md-6">
                      {edit ? (
                        <>
                          {" "}
                          <input
                            type="text"
                            placeholder="Address"
                            className="form-control br"
                            name="customer_address"
                            value={customerDetails.customer_address}
                            onChange={handleChange}
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <input
                            type="text"
                            placeholder="Address"
                            className="form-control br"
                            name="customer_address"
                            value={customerDetails.customer_address}
                            onChange={handleChange}
                            disabled
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {deliveryExecutive && (
                    <div className="delivery-details mt-3">
                      <div className="delivery-data">
                        <img
                          src="images/user2.png"
                          style={{ width: "80px", borderRadius: "50px" }}
                          alt="User"
                        />
                        <p
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "#fff",
                          }}
                        >
                          {deliveryExecutive.first_name}
                          <br />
                          {deliveryExecutive.phone_no}
                          <br />
                          <p>(Delivery Executive)</p>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {loadDeliveryPref && (
                <div className="prefrences-section section" id="prefrence">
                  <p className="theme-color">Delivery Preference</p>
                  <div className="prefrences-section-img">
                    <div
                      className="preference-img"
                      onClick={() => handlePreferenceClick("Ring to Door Bell")}
                    >
                      <img src="img/prefer/5.png" className="prefer-img" alt="" />
                      <p
                        style={{
                          backgroundColor:
                            deliveryPreference === "Ring to Door Bell"
                              ? "#f28123"
                              : "",
                        }}
                      >
                        Ring to Door Bell
                      </p>
                    </div>
                    <div
                      className="preference-img"
                      onClick={() => handlePreferenceClick("In hand Delivery")}
                    >
                      <img src="img/prefer/4.png" className="prefer-img" alt="" />
                      <p
                        style={{
                          backgroundColor:
                            deliveryPreference === "In hand Delivery"
                              ? "#f28123"
                              : "",
                        }}
                      >
                        In hand Delivery
                      </p>
                    </div>
                    <div
                      className="preference-img"
                      onClick={() => handlePreferenceClick("No Preferences")}
                    >
                      <img src="img/prefer/6.png" className="prefer-img" alt="" />
                      <p
                        style={{
                          backgroundColor:
                            deliveryPreference === "No Preferences"
                              ? "#f28123"
                              : "",
                        }}
                      >
                        No Preferences
                      </p>
                    </div>
                    <div
                      className="preference-img"
                      onClick={() => handlePreferenceClick("Drop at the Door")}
                    >
                      <img src="img/prefer/7.png" className="prefer-img" alt="" />
                      <p
                        style={{
                          backgroundColor:
                            deliveryPreference === "Drop at the Door"
                              ? "#f28123"
                              : "",
                        }}
                      >
                        Drop at the Door
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {loadSubs && (
                <>
                  <div
                    class="Subscription-section section theme-color"
                    id="Subscription"
                  >
                    My Subscription
                    <div class="table-section">
                      <br />
                      <table className="table table-hover">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">Products</th>
                            <th scope="col">Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Status</th>
                            <th scope="col"> Type</th>
                            <th scope="col">Every</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subscriptions.map((subscription, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <th scope="row">
                                  <img
                                    src={`images/${subscription.product_name.replace(/\\/g, '/').replace(/ /g, '%20')}.png`}
                                    width="100px"
                                    alt="Product"
                                  />
                                  <p style={{ display: "flex" }} className="product-name">
                                    {subscription.product_name}
                                    <br />
                                    {subscription.product_description}
                                  </p>
                                </th>
                                <td className="theme-color">
                                  &#8377;{subscription.price}
                                </td>
                                <td>{subscription.quantity}</td>
                                <td>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={subscription.status === "1"}
                                      onChange={() =>
                                        toggleSubscriptionStatus(
                                          subscription.id,
                                          subscription.status
                                        )
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </td>
                                <td>{subscription.subscription_type}</td>
                                <td>{subscription.interval} day(s)</td>
                                <td>
                                  <i
                                    className="fas fa-edit"
                                    style={{ paddingRight: "10px", cursor: 'pointer' }}
                                    onClick={() => updateQuantity(subscription)}
                                  ></i>
                                  <i
                                    className="fas fa-cog"
                                    style={{ paddingRight: "10px", cursor: 'pointer' }}
                                    onClick={() =>
                                      handleEditClick(subscription)
                                    }
                                  ></i>
                                  <i
                                    className="fas fa-trash"
                                    style={{ paddingRight: "10px", cursor: 'pointer' }}
                                    onClick={() =>
                                      handleDeleteSubscription(subscription.id)
                                    }
                                  ></i>
                                </td>
                              </tr>
                              {showEditBulkQuantity && editingSubscription &&
                                editingSubscription.id === subscription.id && (
                                  <tr>
                                    <td colSpan="7">
                                      <form onSubmit={handleSaveUP}>
                                        <div className="form-group">
                                          <label>Update Bulk Quantity</label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={quantity}
                                            onChange={(e) =>
                                              setQuantity(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label>Start Date</label>
                                          <input
                                            type="date"
                                            className="form-control"
                                            value={startDate}
                                            onChange={(e) =>
                                              setStartDate(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label>End Date</label>
                                          <input
                                            type="date"
                                            className="form-control"
                                            value={endDate}
                                            onChange={(e) =>
                                              setEndDate(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "end",
                                          }}
                                        >
                                          <button
                                            type="submit"
                                            className="btn btn-primary"
                                          >
                                            Update
                                          </button>
                                        </div>
                                      </form>
                                    </td>
                                  </tr>
                                )}

                              {/* {shoWEditSubscription && editingSubscriptionQuantity &&
                                editingSubscriptionQuantity.id ===
                                subscription.id && (
                                  <tr>
                                    <td colSpan="9">
                                      <form onSubmit={handleUpdateQuantity}>
                                        <div className="form-group">
                                          <label>Update Quantity</label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={quantity}
                                            onChange={(e) =>
                                              setQuantity(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                        <div className="form-group">
                                          
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "end",
                                          }}
                                        >
                                          <button
                                            type="submit"
                                            className="subscribe-button"

                                          >
                                            Update
                                          </button>
                                        </div>
                                      </form>
                                    </td>
                                  </tr>
                                )} */}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
              {loadWallet && (
                <div class="wallet-section section" id="wallet">
                  <div class="row">
                    <div class="col-md-7 col-lg-7 col-sm-12">
                      <p class="theme-color">Wallet History </p>
                      <div class="wallet-history">
                        <table class="table table-light">
                          <thead>
                            <tr>
                              <th scope="col">Transaction Date</th>
                              <th scope="col">Type</th>
                              <th scope="col">Reason</th>
                              <th scope="col">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {walletHistory.map((entry, index) => (
                              <tr key={index}>
                                <td className="theme-color">
                                  {moment(entry.created_date.toDate()).format(
                                    "MM/DD/YYYY"
                                  )}
                                </td>
                                <td
                                  style={{
                                    color:
                                      entry.type === "Credit" ? "green" : "red",
                                  }}
                                >
                                  {entry.type}
                                </td>
                                <td className="theme-color">{entry.reason}</td>
                                <td className="theme-color">
                                  &#8377;{entry.amount}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-5 col-lg-5 col-sm-12">
                      <div class="recharge-section">
                        <p class="theme-color">ADD MONEY</p>
                        <p>
                          <b>Select Quick Amount</b>
                        </p>
                        <div className="amount-select-btn mb-3">
                          {[500, 1000, 1500, 2000].map((amount) => (
                            <div className="item-save" key={amount}>
                              <button
                                type="button"
                                className="btn btn-light theme-color btn-amount"
                                onClick={() => handleQuickAmountSelect(amount)}
                              >
                                &#8377;{amount}
                              </button>
                            </div>
                          ))}
                        </div>
                        <form>
                          <div className="mb-3">
                            <input
                              type="text"
                              placeholder="Enter Amount"
                              className="form-control br"
                              value={amount}
                              required
                              onChange={(e) => setAmount(e.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <input
                              type="text"
                              placeholder="Email"
                              className="form-control br"
                              value={email}
                              required
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </form>

                        {/* <p>Select Payment Method</p> */}
                        <div class="payment-method">
                          <div
                          //onClick={() => setPaymentMethod("Online")}
                          // style={{
                          //   backgroundColor:
                          //     paymentMethod === "Online" ? "#dee2e6" : "",
                          // }}
                          >
                            <div className="subscribe-button" style={{ backgroundColor: 'black', cursor: 'pointer' }} onClick={handleAddMoney}>
                              <i class="fas fa-wallet" style={{ color: '#fff' }}></i>
                              <span style={{ marginLeft: '5px' }}>Pay Online</span>
                            </div>

                          </div>

                        </div>
                        {/* <button
                          type="button"
                          onClick={handleAddMoney}
                          class="btn btn-light pay-btn theme-color d-flex justify-content-center"
                        >
                          Pay Now
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {loadOrders && (
                <>
                  <div className="wallet-section section" id="order">
                    <div className="row">
                      <div className="col-md-7 col-lg-7 col-sm-12">
                        <p className="theme-color">Orders</p>
                        <div className="wallet-history">
                          <table className="table table-light">
                            <thead>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Order ID.</th>
                                <th scope="col">Delivery By</th>
                                <th scope="col">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {uniqueOrders.map((order) => (
                                <tr
                                  key={order.order_id}
                                  onClick={() => handleOrderClick(order)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <th scope="row">
                                    {moment(
                                      order.delivery_timestamp.toDate()
                                    ).format("MM/DD/YYYY")}
                                  </th>
                                  <td className="theme-color">
                                    {order.order_id}
                                  </td>
                                  <td className="theme-color">
                                    {order.hub_name}
                                  </td>
                                  <td className="theme-color">
                                    &#8377;{order.price}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-5 col-lg-5 col-sm-12">
                        {selectedOrder && (
                          <div className="recharge-section mt-3">
                            <p className="theme-color">Order Details</p>
                            <div className="deliver-detail mb-3">
                              <div className="deliverd">
                                <b>Delivered By:</b>
                                <br />
                                {selectedOrder.hub_name}
                              </div>
                              <div className="deliverd">
                                <b>Delivered On:</b>
                                <br />
                                {moment(
                                  selectedOrder.delivery_timestamp.toDate()
                                ).format("MM/DD/YYYY")}
                              </div>
                            </div>
                            <div className="deliver-detail mb-3">
                              <div className="deliverd">
                                <b>Delivered To:</b>
                                <br />
                                {selectedOrder.delivering_to}
                              </div>
                              <div className="deliverd">
                                <p
                                  style={{
                                    backgroundColor:
                                      selectedOrder.status === "1"
                                        ? "#198754"
                                        : selectedOrder.status === "0"
                                          ? "#007bff"
                                          : "#dc3545",
                                  }}
                                >
                                  {selectedOrder.status === "1"
                                    ? "Delivered"
                                    : selectedOrder.status === "0"
                                      ? ""
                                      : "Cancelled"}
                                </p>
                              </div>
                            </div>
                            <table className="table table-light">
                              <thead>
                                <tr>
                                  <th scope="col">Product</th>
                                  <th scope="col">Qty</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedOrder.products &&
                                  selectedOrder.products.length > 0 ? (
                                  selectedOrder.products.map(
                                    (product, index) => (
                                      <tr key={index}>
                                        <th scope="row">
                                          {/* <img src={product.image} style={{ width: '10px' }} alt={product.name} /> */}
                                          {product.name}
                                        </th>
                                        <td className="theme-color">
                                          {product.quantity}
                                        </td>
                                        <td className="theme-color">
                                          {product.package_unit}
                                        </td>
                                        <td className="theme-color">
                                          &#8377;{product.price}
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan="4">
                                      No products found for this order
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {loadCalendar && (
                <>
                  <div className="wallet-section section" id="order">
                    <div className="row">
                      <div className="col-md-12 col-lg-12 col-sm-12">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="theme-color">Calendar</p>
                          <div className="datepicker-container">
                            <label
                              className="datepicker-label"
                              style={{ marginRight: "10px" }}
                            >
                              Delivery Date :{" "}
                            </label>
                            <DatePicker
                              selected={futureDate}
                              onChange={handleFutureDateChange}
                              selectsStart
                              startDate={futureDate}
                              minDate={new Date()}
                              //endDate={endDate}
                              dateFormat="dd-MM-yyyy" // Format for displaying the date
                              className="datepicker-input"
                              placeholderText="Delivery date"
                            />
                          </div>
                        </div>

                        <br />
                        <div className="wallet-history">
                          <table className="table table-light">
                            <thead>
                              <tr>
                                <th scope="col">Delivery Date</th>
                                <th scope="col">Product Name</th>
                                <th scope="col">Quantity</th>
                                {/* <th scope="col">Updated Qty</th> */}
                                <th scope="col">Every</th>
                                <th scope="col">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {advanceOrders.map((order, index) => (
                                <React.Fragment key={index}>
                                  <tr key={order.subscription_id}>
                                    <th scope="row">
                                      {moment(futureDate).format("YYYY-MM-DD")}
                                    </th>
                                    <td className="theme-color">{order.product_name}</td>
                                    <td className="theme-color">
                                      <button
                                        className="btn btn-sm btn-warning rounded"
                                        onClick={() => updateQuantityBulk(order, 'decrement')}
                                      >
                                        -
                                      </button>
                                      <span style={{ margin: "0 10px" }}>{order.quantity}</span>
                                      <button
                                        className="btn btn-sm btn-warning rounded"
                                        onClick={() => updateQuantityBulk(order, 'increment')}
                                      >
                                        +
                                      </button>
                                    </td>
                                    {/* <td className="theme-color">

                                      <span style={{ margin: "0 10px" }}>{advancedOrderID[`${order.product_name}-${moment(futureDate).format("YYYY-MM-DD")}`]}</span>

                                    </td> */}
                                    <td className="theme-color">
                                      {order.subscription_type === "Custom" ? "-" : `${order.interval} day(s)`}
                                    </td>
                                    <td className="theme-color">&#8377;{order.price}</td>
                                  </tr>
                                  {editingOrder && editingOrder.subscription_id === order.subscription_id && (
                                    <tr>
                                      <td colSpan="7">
                                        <form onSubmit={handleAddEntry}>
                                          <div className="form-group">
                                            <label>Update Bulk Quantity</label>
                                            <input
                                              type="number"
                                              className="form-control"
                                              value={quantityBulk}
                                              onChange={(e) => setQuantityBulk(e.target.value)}
                                              required
                                            />
                                          </div>
                                          <div style={{ display: "flex", justifyContent: "end" }}>
                                            <button type="submit" className="btn btn-primary">
                                              Add
                                            </button>
                                          </div>
                                        </form>
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>

                      </div>
                      <div className="col-md-5 col-lg-5 col-sm-12"></div>
                    </div>
                  </div>
                </>
              )}
              {/* {loadVacations && (
                <div class="wallet-section section" id="wallet">
                  <div class="row">
                    <div class="col-md-7 col-lg-7 col-sm-12">
                      <p class="theme-color">Wallet History </p>
                      <div class="wallet-history">
                        <table class="table table-light">
                          <thead>
                            <tr>
                              <th scope="col">Transaction Date</th>
                              <th scope="col">Type</th>
                              <th scope="col">Reason</th>
                              <th scope="col">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {walletHistory.map((entry, index) => (
                              <tr key={index}>
                                <td className="theme-color">
                                  {moment(entry.created_date.toDate()).format(
                                    "MM/DD/YYYY"
                                  )}
                                </td>
                                <td
                                  style={{
                                    color:
                                      entry.type === "Credit" ? "green" : "red",
                                  }}
                                >
                                  {entry.type}
                                </td>
                                <td className="theme-color">{entry.reason}</td>
                                <td className="theme-color">
                                  &#8377;{entry.amount}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    
                  </div>
                </div>
              )} */}
            </div>
            {loadEditSubModal && <Dialog isOpen={loadEditSubModal} onClose={onCloseDialog} subscription={selectedSub}></Dialog>}
          </div>
        </div>
        <br />
        <Footer />
      </div>

    </>
  );
}

export default Profile;
