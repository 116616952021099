import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

function BookYourFarm() {
    const [generatedCaptcha, setGeneratedCaptcha] = useState('');

    const generateCaptcha = () => {
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let captchaLength = 6;
        let captcha = '';
        for (let i = 0; i < captchaLength; i++) {
            let randomIndex = Math.floor(Math.random() * characters.length);
            captcha += characters[randomIndex];
        }
        setGeneratedCaptcha(captcha);
    }

    useEffect(() => {
        generateCaptcha();
    }, []);

    return (
        <>
            <Navbar/>
            <div className="header-banner-bookfarm">
                <div className="container banner-heading-bookfarm">
                <div class="white-cow-img" style={{display:'flex', justifyContent:'center'}}>
    <img src="images/white-cow.png"/>
  </div>
                    <h1 className="text-center">BOOK YOUR FARM VISIT</h1>
                </div>
            </div>
            <div className="container form-container-bookfarm">
                <div className="row mt-5 form-content form-container-row-bookfarm">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <form action="" method="post" id="contactForm">
                            <div className="mb-3">
                                <input type="text" placeholder="Your Full Name *" className="form-control br-bookfarm"/>
                            </div>
                            <div className="mb-3">
                                <input type="email" placeholder="Email ID *" className="form-control br-bookfarm"/>
                            </div>
                            <div className="mb-3">
                                <input type="number" placeholder="Mobile Number *" className="form-control br-bookfarm"/>
                            </div>
                            <div className="mb-3">
                                <label>Date of Visit</label>
                                <input type="date" className="form-control br-bookfarm"/>
                            </div>
                            <div className="mb-3">
                                <label>Choose Time Slot</label>
                                <select className="form-select" aria-label="Default select example">
                                    <option value="9:00 AM - 11:00 AM">9:00 AM - 11:00 AM</option>
                                    <option value="11:00 AM - 1:00 PM">11:00 AM - 1:00 PM</option>
                                    <option value="2:00 PM - 4:00 PM">2:00 PM - 4:00 PM</option>
                                    <option value="4:00 PM - 6:00 PM">4:00 PM - 6:00 PM</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <textarea className="form-control br-bookfarm" rows="3" placeholder="Write Message/Any Query ? *"></textarea>
                            </div>
                            <div className="mb-3">
                                <input type="text" id="captchaInput" placeholder="Enter Captcha Code *" className="form-control br-bookfarm"/>
                                <small id="captchaHelp" className="form-text text-muted">Please enter the CAPTCHA code above.</small>
                            </div>
                            <div className="mb-3">
                                <input type="text" id="generatedCaptcha" className="form-control br-bookfarm" value={generatedCaptcha} readOnly/>
                            </div>
                            <div className="mb-3">
                                <button type="button" onClick={generateCaptcha} className="captcha-btn br-bookfarm">Generate CAPTCHA</button>
                            </div>
                            <input type="submit" className="form-control br bg-colss-bookfarm"/>
                        </form>
                        <div id="captchaMessage" className="mt-3"></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h3>Farm Address</h3>
                            <p><strong>Whyte Farms LLP</strong><br/>Village Shahbad, Tehsil Tijara,<br/>Distt. Alwar,Rajasthan -301411, <br/>India.<br/><br/>Timing - 9:00 AM to 7:00 PM</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default BookYourFarm