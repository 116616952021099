import React, { useState } from 'react';
//import './CalendarPanel.css';

const CalendarPanel = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const handleDateClick = (day) => {
    const selected = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    if (selected >= new Date()) {
      setSelectedDate(selected);
      searchOperation(selected);
    }
  };

  const handlePrevMonth = () => {
    const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    if (prevMonth >= new Date(new Date().getFullYear(), new Date().getMonth(), 1)) {
      setCurrentDate(prevMonth);
    }
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const searchOperation = (date) => {
    console.log(`Search operation performed for date: ${date}`);
    // Add your search operation logic here
  };

  const renderDays = () => {
    const today = new Date();
    const totalDays = daysInMonth(currentDate.getMonth(), currentDate.getFullYear());
    const days = [];
    for (let day = 1; day <= totalDays; day++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      const isPast = date < today.setHours(0, 0, 0, 0);
      days.push(
        <div
          key={day}
          className={`day ${selectedDate && selectedDate.getDate() === day && selectedDate.getMonth() === currentDate.getMonth() && selectedDate.getFullYear() === currentDate.getFullYear() ? 'active' : ''} ${isPast ? 'disabled' : ''}`}
          onClick={() => !isPast && handleDateClick(day)}
        >
          {day}
        </div>
      );
    }
    return days;
  };

  const renderWeekdays = () => {
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return weekdays.map((day, index) => <div key={index}>{day}</div>);
  };

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  return (
    // <div className="calendar-panel">
      <div className="calendar">
        <div className="calendar-header">
          <button onClick={handlePrevMonth}>&lt;</button>
          <span className="month">{monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}</span>
          <button onClick={handleNextMonth}>&gt;</button>
        </div>
        <div className="calendar-body">
          <div className="weekdays">{renderWeekdays()}</div>
          <div className="days">{renderDays()}</div>
        </div>
      </div>
    // </div>
  );
};

export default CalendarPanel;
