import React, { useEffect, useState } from 'react';
import db from "../Firebase";
import Moment from "moment";
import { extendMoment } from "moment-range";

const Dialog = ({onClose , subscription}) => {
    const moment = extendMoment(Moment);
    const [showDatePickers, setShowDatePickers] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [showOnInterval, setShowOnInterval] = useState(false);
    const [showCustom, setShowCustom] = useState(false);
    const [intervalDays, setIntervalDays] = useState(2);
    const [nextDeliveryDate, setNextDeliveryDate] = useState('');
    const [activeFrequency, setActiveFrequency] = useState();
    const [quantity, setQuantity] = useState(1);
    const [subscriptionType, setSubscriptionType] = useState();
    const [product, setProduct] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [customDays, setCustomDays] = useState({
        sunday: 0,
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0
    });

    const incrementQuantity = () => setQuantity(quantity + 1);
    const decrementQuantity = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

    const incrementInterval = () => setIntervalDays(intervalDays + 1);
    const decrementInterval = () => setIntervalDays(intervalDays > 2 ? intervalDays - 1 : 2);

    const fetchProductDetails = async () => {
        debugger;
        try {
            const doc = await db.collection('products_data')
            .where('product_name' , '==' , subscription.product_name)
            .get();

            if (doc.exists) {
                const productData = doc.data();
                console.log("product data =>",productData);
                setProduct(productData);
                setSelectedPackage(productData.packagingOptions[0]);
            } else {
                console.log('No such document!');
            }
        } catch (error) {
            console.error('Error getting document:', error);
        }
    };
    
    useEffect(()=> {
        fetchProductDetails();
        setActiveFrequency(subscription.subscription_type);
        setQuantity(subscription.quantity);
        if(subscription.subscription_type == 'On-Interval') {
            setIntervalDays(subscription.interval);
            setShowOnInterval(true);
        }else if(subscription.subscription_type == 'Custom') {
            setShowCustom(true);
            debugger;
            Object.keys(customDays).forEach((day) => {
                if (subscription[day] !== 0) {
                  setCustomDays(prevState => ({
                    ...prevState,
                    [day]: subscription[day]
                  }));
                }
              });
        }
        setStartDate(new Date(subscription.start_date));
        setEndDate(new Date(subscription.end_date));
        setShowDatePickers(subscription.subscription_type !== "One-Time");
    },[subscription]);

    

    const handleFrequencyClick = (frequency) => {
        setActiveFrequency(frequency);
        setSubscriptionType(frequency);
        setShowDatePickers(frequency !== "One-Time");
        setShowOnInterval(frequency === "On-Interval");
        setShowCustom(frequency === "Custom");
    };

    const handleCustomDayChange = (day, value) => {
        setCustomDays(prevState => ({
            ...prevState,
            [day]: value
        }));
    };

    const updateSubscription = async () => {
        try {
            await db.collection('subscription_data').doc(subscription.id).update({
                subscription_type: subscriptionType,
                quantity: parseInt(quantity),
                start_date: new Date(startDate),
                end_date: new Date(endDate),
                sunday: parseInt(customDays.sunday) || 0,
                monday: parseInt(customDays.monday) || 0,
                tuesday: parseInt(customDays.tuesday) || 0,
                wednesday: parseInt(customDays.wednesday) || 0,
                thursday: parseInt(customDays.thursday) || 0,
                friday: parseInt(customDays.friday) || 0,
                saturday: parseInt(customDays.saturday) || 0,
                interval: subscriptionType === 'On-Interval' ? parseInt(intervalDays) : subscriptionType === "Everyday" ? 1 : 0,
                next_delivery_date: nextDeliveryDate,
            });
            console.log('Subscription updated successfully!');
        } catch (error) {
            console.error('Error updating subscription:', error);
        }
    };

  return (
    <div className="dialog-overlay">
      <div className="dialog-box">
        <div className="dialog-header">Edit Subscription</div>
    <div className="dialog-content">
            <main>
                <div className="product-detail-container">
                    <div >
                        <div className="product-detail-left">
                            {/* <img src={subscription.image} className="img-fluid rounded-start product-detail-page-image" alt={product.productName} style={{ height: "30rem" }} /> */}
                        </div>
                        <div>
                            <h2>{subscription.product_name}</h2>
                            <p>Frequently</p>
                            <div className="frequency-buttons">
                                {['Everyday', 'One-Time', 'Custom', 'On-Interval'].map(frequency => (
                                    <button
                                        key={frequency}
                                        className={`frequency-button ${activeFrequency === frequency ? 'active' : ''}`}
                                        onClick={() => handleFrequencyClick(frequency)}
                                    >
                                        {frequency}
                                    </button>
                                ))}
                            </div>
                            {!showCustom && (<>
                                <p style={{ marginTop: '10px' }}>Quantity</p>
                                <div className="quantity-selector">
                                    <button onClick={decrementQuantity}>-</button>
                                    <span>{quantity}</span>
                                    <button onClick={incrementQuantity}>+</button>
                                </div>
                            </>)}
                            {showOnInterval && (
                                <>
                                    <p style={{ marginTop: '10px' }}>Interval Days</p>
                                    <div className="quantity-selector">
                                        <button onClick={decrementInterval}>-</button>
                                        <span>{intervalDays}</span>
                                        <button onClick={incrementInterval}>+</button>
                                    </div>
                                </>
                            )}
                            {showCustom && (
                                <>
                                <div className="custom-days" style={{ display: "ruby-text" }}>
                                    {Object.keys(customDays).map((day) => (
                                        <div key={day} className="form-group">
                                            <label htmlFor={day}>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id={day}
                                                value={customDays[day]}
                                                onChange={(e) => handleCustomDayChange(day, e.target.value)}
                                            />
                                        </div>
                                    ))}
                                </div>
                                </>
                            )}
                            {showDatePickers && (
                                <div className="row">
                                    <div className="col-6 mt-4">
                                        <h5 style={{ fontSize: "12px" }}>Started On</h5>
                                        <input type="date" className="form-control" value={moment(subscription.start_date.toDate()).format('YYYY-MM-DD')} disabled/>
                                    </div>
                                    {/* <div className="col-6 mt-4">
                                        <h5 style={{ fontSize: "12px" }}>Ends On</h5>
                                        <input type="date" className="form-control" value={subscription.end_date} onChange={(e) => setEndDate(e.target.value)} />
                                    </div> */}
                                </div>
                            )}
                            {/* <button className="subscribe-button" style={{ marginTop: '10px', fontSize: 'small' }} onClick={addToCart}>Add To Cart</button> */}
                        </div>
                    </div>
                </div>
            </main>
        </div>
        <div className="dialog-footer">
            <button className="subscribe-button" style={{backgroundColor:'black' , marginRight:'1rem'}} onClick={updateSubscription}>Update</button>
            <button className="subscribe-button" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
