import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Cancelpayment = () => {

    return (
        <div>
            <h1>Payment Failed</h1>

        </div>
    );
};

export default Cancelpayment;
