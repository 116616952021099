import React, { useState } from 'react';
import axios from 'axios';

const TestNode = () => {
    const [getData, setGetData] = useState('');
    const [postData, setPostData] = useState('');
    const [postResponse, setPostResponse] = useState('');

    // Function to handle GET request
    const handleGetData = async () => {
        try {
            const response = await axios.get('https://whytefarms.in/api/data');
            setGetData(response.data.message);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Function to handle POST request
    const handlePostData = async () => {
        try {
            const response = await axios.post('https://whytefarms.in/api/data', { message: postData });
            setPostResponse(response.data.message);
        } catch (error) {
            console.error('Error posting data:', error);
        }
    };

    return (
        <div>
            <h1>React and Node.js Example</h1>

            <div>
                <h2>GET Request</h2>
                <button onClick={handleGetData}>Get Data</button>
                <p>Response: {getData}</p>
            </div>

            <div>
                <h2>POST Request</h2>
                <input
                    type="text"
                    value={postData}
                    onChange={(e) => setPostData(e.target.value)}
                    placeholder="Enter data to post"
                />
                <button onClick={handlePostData}>Post Data</button>
                <p>Response: {postResponse}</p>
            </div>
        </div>
    );
};

export default TestNode;
