import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import db from "../Firebase";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ProductDetail.css';
import { useNavigate } from 'react-router-dom';
import Moment from "moment";
import { extendMoment } from "moment-range";
import Breadcrumbs from './Breadcrumbs';
import { Link } from 'react-router-dom';

const UpdateSubscription = () => {
    const moment = extendMoment(Moment);
    const navigate = useNavigate();
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [subscriptionType, setSubscriptionType] = useState("Everyday");
    const [quantity, setQuantity] = useState(1);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [customDays, setCustomDays] = useState({
        sunday: 0,
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0
    });
    const [intervalDays, setIntervalDays] = useState(2);
    const [nextDeliveryDate, setNextDeliveryDate] = useState('');
    const [showDatePickers, setShowDatePickers] = useState(false);
    const [showOnInterval, setShowOnInterval] = useState(false);
    const [showCustom, setShowCustom] = useState(false);
    const [activeFrequency, setActiveFrequency] = useState('Everyday');

    const incrementQuantity = () => setQuantity(quantity + 1);
    const decrementQuantity = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

    const incrementInterval = () => setIntervalDays(intervalDays + 1);
    const decrementInterval = () => setIntervalDays(intervalDays > 2 ? intervalDays - 1 : 2);

    const handleFrequencyClick = (frequency) => {
        setActiveFrequency(frequency);
        setSubscriptionType(frequency);

        setShowDatePickers(frequency !== "One-Time");
        setShowOnInterval(frequency === "On-Interval");
        setShowCustom(frequency === "Custom");
    };

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const doc = await db.collection('products_data').doc("RPwD6aO5OAfVy5DFBtGl").get();
                if (doc.exists) {
                    const productData = doc.data();
                    setProduct(productData);
                    setSelectedPackage(productData.packagingOptions[0]);
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error getting document:', error);
            }
        };

        fetchProductDetails();
    }, [id]);

    useEffect(() => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        const nextYear = new Date(today);
        nextYear.setFullYear(today.getFullYear() + 1);

        setStartDate(formatDate(tomorrow));
        setEndDate(formatDate(nextYear));
        setNextDeliveryDate(formatDate(tomorrow));
    }, []);

    const updateSubscription = async () => {
        try {
            await db.collection('subscription_data').doc("chS7LdjrA8dXvozCSboM").update({
                subscription_type: subscriptionType,
                quantity: parseInt(quantity),
                start_date: new Date(startDate),
                end_date: new Date(endDate),
                sunday: parseInt(customDays.sunday) || 0,
                monday: parseInt(customDays.monday) || 0,
                tuesday: parseInt(customDays.tuesday) || 0,
                wednesday: parseInt(customDays.wednesday) || 0,
                thursday: parseInt(customDays.thursday) || 0,
                friday: parseInt(customDays.friday) || 0,
                saturday: parseInt(customDays.saturday) || 0,
                interval: subscriptionType === 'On-Interval' ? parseInt(intervalDays) : subscriptionType === "Everyday" ? 1 : 0,
                next_delivery_date: nextDeliveryDate,
            });
            console.log('Subscription updated successfully!');
        } catch (error) {
            console.error('Error updating subscription:', error);
        }
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    };

    const handleCustomDayChange = (day, value) => {
        setCustomDays(prevState => ({
            ...prevState,
            [day]: value
        }));
    };

    // Remaining code for rendering UI and handling user interactions...

    return (
        <>
            <main>
                <div className="product-detail-container">
                    <div className="product-detail-card">
                        <div className="product-detail-left">
                            {/* <img src={product.image} className="img-fluid rounded-start product-detail-page-image" alt={product.productName} style={{ height: "30rem" }} /> */}
                        </div>
                        <div className="product-detail-right">
                            {/* <h2>{product.productName}</h2> */}
                            <Link to="/" className='edit-button'>
                                <span style={{ color: 'black', fontWeight: 'bold', color: '#e1a95f', textDecoration: 'none' }}>Continue Shopping</span>
                            </Link>


                            {/* <div className="frequency-buttons">
                        {product.packagingOptions.map(pkg => (
                            <button
                                key={pkg.packaging}
                                className="frequency-button"
                                onClick={() => setSelectedPackage(pkg)}
                            >
                                {pkg.packaging} {pkg.pkgUnit}
                            </button>
                        ))}
                    </div> */}
                            {/* <p className="product-price">₹{selectedPackage.price}</p> */}
                            <div className="product-description">
                                <p>This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                            </div>
                            <p className="order-info">
                                Ordered by 11:00 PM Today & get the delivery by <strong>{moment(nextDeliveryDate).format('MMMM D, YYYY')}</strong>
                            </p>
                            <p>Frequently</p>
                            <div className="frequency-buttons">
                                {['Everyday', 'One-Time', 'Custom', 'On-Interval'].map(frequency => (
                                    <button
                                        key={frequency}
                                        className={`frequency-button ${activeFrequency === frequency ? 'active' : ''}`}
                                        onClick={() => handleFrequencyClick(frequency)}
                                    >
                                        {frequency}
                                    </button>
                                ))}
                            </div>
                            {!showCustom && (<>
                                <p style={{ marginTop: '10px' }}>Quantity</p>
                                <div className="quantity-selector">
                                    <button onClick={decrementQuantity}>-</button>
                                    <span>{quantity}</span>
                                    <button onClick={incrementQuantity}>+</button>
                                </div>
                            </>)}
                            {showOnInterval && (
                                <>
                                    <p style={{ marginTop: '10px' }}>Interval Days</p>
                                    <div className="quantity-selector">
                                        <button onClick={decrementInterval}>-</button>
                                        <span>{intervalDays}</span>
                                        <button onClick={incrementInterval}>+</button>
                                    </div>
                                </>
                            )}
                            {showCustom && (
                                <div className="custom-days" style={{ display: "ruby-text" }}>
                                    {Object.keys(customDays).map((day) => (
                                        <div key={day} className="form-group">
                                            <label htmlFor={day}>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id={day}
                                                value={customDays[day]}
                                                onChange={(e) => handleCustomDayChange(day, e.target.value)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            {showDatePickers && (
                                <div className="row">
                                    <div className="col-6 mt-4">
                                        <h5 style={{ fontSize: "12px" }}>Starts On</h5>
                                        <input type="date" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <h5 style={{ fontSize: "12px" }}>Ends On</h5>
                                        <input type="date" className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                    </div>
                                </div>
                            )}
                            {/* <button className="subscribe-button" style={{ marginTop: '10px', fontSize: 'small' }} onClick={addToCart}>Add To Cart</button> */}
                        </div>
                    </div>
                    <div className="product-detail-right">
                        {/* Product details */}
                        <button className="update-button" onClick={updateSubscription}>Update</button>
                    </div>
                </div>
            </main>
        </>
    );
};

export default UpdateSubscription;

