import React, { useState , useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';

const Navbar = () => {
    const { user, logout } = useContext(AuthContext);
    const [menuActive, setMenuActive] = useState(false);
    const [loadProfileOptions , setLoadProfileOptions] = useState(false);
    const [loadStaticOptions , setLoadStaticOptions] = useState(false);
    const [right , setRight] = useState('10.2rem');
    const navigate = useNavigate();
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        setCartItems(storedCart);
        
    }, []);

    const handleProfileClick = (option) => {
        setLoadProfileOptions(false);
        setLoadStaticOptions(false);
        if(option === 'profile') {
            setLoadProfileOptions(true);
            setRight('10.2rem')
        }else {
            setLoadStaticOptions(true);
            setRight('1.2rem');
        }
        setMenuActive(!menuActive);
      };

      const gotocart = () => {
        navigate('/cart');
    }

    const gotologin = () => {
        navigate('/login');
    }

    const goToProfile = () => {
        navigate('/profile');
    }

    const gotohome = () => {
        navigate('/');
    }

    const gotoOurStory = () => {
        navigate('/ourstory');
    }

    const gotoProcess = () => {
        navigate('/process');
    }

    const gotoOurFarm = () => {
        navigate('/ourfarm');
    }

    const gotoGallery = () => {
        navigate('/gallery');
    }

    const gotoContact = () => {
        navigate('/contactus');
    }

    const handleLogout = () => {
        logout();
        setCartItems([]);
        localStorage.removeItem('cart');
        navigate('/');
      };

    return (
        // <nav>
        //     <Link to="/">Dashboard</Link>
        //     {user ? (
        //         <>
        //             <Link to="/account">Account</Link>
        //             <button onClick={logout}>Logout</button>
        //         </>
        //     ) : (
        //         <Link to="/login">Login</Link>
        //     )}
        // </nav>

        <nav>
	
    <div className="overlay">
                    <img onClick={gotohome} src="../images/logo-icon.webp" alt="Whyte Farms" height={'75px'} style={{ marginTop: '5px' , cursor:'pointer'}} />
                </div>
	<div class="profile" onClick={() => handleProfileClick('profile')}>
		<div class="user user-1">
        <img class="user-icon" src="../images/user2.png" alt="Whyte Farms" height={'40px'} style={{ marginTop: '5px', marginLeft: "2rem", cursor: "pointer" }} />
        <img class="user-icon user-icon-2" onClick={gotocart} src="../images/cart2.png" alt="Whyte Farms"  height={'40px'} style={{ marginTop: '5px', marginLeft: "2rem", cursor: "pointer" }}/>
        {cartItems.length > 0 ? <span class='badge badge-warning' id='lblCartCount'>{cartItems.length}</span>:<></>}
		</div>
	</div>
    <div class='user' onClick={() => handleProfileClick('static')}>
    <img class="user-toggle" src="../images/tog.png" alt="Whyte Farms"  height={'25px'} style={{ marginTop: '5px', marginLeft: "2rem", cursor: "pointer" }}/>
    </div>
	<div className={`menu ${menuActive ? 'active' : ''}`} style={{right:right}}>
		{loadProfileOptions && <ul>
			<li>{user ? <a onClick={goToProfile}><i class="ph-bold ph-user"></i>&nbsp;Profile</a>:<a onClick={gotologin}><i class="ph-bold ph-user"></i>&nbsp;Login</a>}</li>
			{user && <li><a onClick={handleLogout} ><i class="ph-bold ph-gear-six"></i>&nbsp;Logout</a></li>}
            
		</ul>
        }
        
        {loadStaticOptions && <ul>
                                    <li><a onClick={gotohome}>Home</a></li>
                                    <li><a onClick={gotoOurStory}>Our Story</a></li>
                                    <li><a onClick={gotoOurFarm}>Our Farm</a></li>
                                    <li><a onClick={gotoContact}>Contact Us</a></li>
                                </ul>
        }
	</div>
    
    
</nav>
    );
};

export default Navbar;
