// src/components/Cart.js

import React from 'react';
import { Link } from 'react-router-dom';

const EmptyCart = () => {
  return (
    <div style={{marginTop:'10rem' , marginLeft:'25rem'}}>
      <div className="text-center">
        <h2>Your Cart is Empty</h2>
        <p>Looks like you haven't added anything to your cart yet.</p>
        <br/>
        <Link to="/" className="subscribe-button">
          <span style={{color:'black' , fontWeight:'bold'}}>Continue Shopping</span>
        </Link>
      </div>
    </div>
  );
};

export default EmptyCart;
