import React from 'react';
import { useLocation } from 'react-router-dom';

const Abc = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const data = JSON.parse(query.get('data') || '{}');

    const phone = data.phone || 'N/A';
    const amount = data.amount || 'N/A';

    return (
        <div style={{ marginTop: '10rem', textAlign: 'center' }}>
            <h2>Payment Failed</h2>
            <p>Unfortunately, your payment could not be processed. Please try again.</p>
            <p><strong>Phone Number:</strong> {phone}</p>
            <p><strong>Amount:</strong> {amount}</p>
        </div>
    );
};

export default Abc;