import React from 'react'
import Navbar from './Navbar';
import Footer from "./Footer";

function ContactUs() {
    return (
        <>
            <div class="header-banner-contact">
                <div class="container banner-heading banner-contact">
                    <Navbar />
                    <div class="white-cow-img">
                        <center><img src="images/white-cow.png" /></center>
                    </div>
                    <h2 class="text-center text-light texts">Contact Us</h2>
                </div>
            </div>
            <div class="container main-container-contactUs">
                <div class="row content-center">
                    <div class="col-lg-10 col-md-8 col-sm-12">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <h3>Corporate Office</h3>
                                <h4 class="text-col">Whyte Farms</h4>
                                <address>
                                    NH-8, Near Rajokri Flyover,
                                    Rajokri, Delhi - 110038,
                                    India

                                </address>
                                <div class="contact-info">
                                    <span><i class="fas fa-mobile-alt"></i>9873710709</span><br />
                                    <span><i class="far fa-envelope"></i> care@whytefarms.com</span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <h3>Farm Address</h3>
                                <h4 class="text-col">Whyte Farms LLP</h4>
                                <address>
                                    Village Shahbad, Tehsil Tijara,
                                    Distt. Alwar, Rajasthan -301411,
                                    India

                                </address>
                                <span>Timing -9.00 AM to 7.00 PM</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5 form-content">
                    <div class="col-lg-10 col-md-10 col-sm-12">
                        <form action="" method="post" id="contactForm">
                            <div class="mb-3">
                                <input type="text" placeholder="Your Full Name *" class="form-control br" />
                            </div>
                            <div class="mb-3">
                                <input type="email" placeholder="Email ID *" class="form-control br" />
                            </div>
                            <div class="mb-3">
                                <input type="number" placeholder="Mobile Number *" class="form-control br" />
                            </div>
                            <div class="mb-3">
                                <textarea class="form-control br" id="exampleFormControlTextarea1" rows="3"
                                    placeholder="Write Message/Any Query ? *"></textarea>
                            </div>
                            <div class="mb-3">
                                <input type="text" id="generatedCaptcha" class="form-control br" readonly />
                            </div>
                            <div class="mb-3">
                                <input type="text" id="captchaInput" placeholder="Enter Captcha Code *" class="form-control br" />
                                <small id="captchaHelp" class="form-text text-muted">Please enter the CAPTCHA code
                                    above.</small>
                            </div>
                            <div class="mb-3">
                                <button type="button" onclick="generateCaptcha()" class="btn btn-outline-info br">Generate
                                    CAPTCHA</button>
                            </div>


                            <input type="submit" class="form-control br bg-colss" />
                        </form>
                        <div id="captchaMessage" class="mt-3"></div>
                    </div>
                </div>
            </div>
            <br />
            <Footer />
        </>
    )
}

export default ContactUs
