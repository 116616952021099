import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import db from "../Firebase";

const PaymentSuccess = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const data = JSON.parse(query.get('data') || '{}');
    const amount = data.amount; // Extract the amount from the data object
    const customerId = data.customer_id; // Ensure the customer_id is part of the data object

    useEffect(() => {
        const updateWalletBalance = async () => {
            try {
                // Assuming you have the customer ID and document ID to update
                const docRef = db.collection('subscription_data').doc(customerId);

                // Fetch current wallet_balance
                const doc = await docRef.get();
                if (doc.exists) {
                    const currentData = doc.data();
                    const newWalletBalance = (currentData.wallet_balance || 0) + parseFloat(amount);

                    // Update wallet_balance
                    await docRef.update({ wallet_balance: newWalletBalance });

                    console.log('Wallet balance updated successfully');
                } else {
                    console.error('Document not found');
                }
            } catch (error) {
                console.error('Error updating wallet balance:', error);
            }
        };

        if (amount && customerId) {
            updateWalletBalance();
        }
    }, [amount, customerId]);

    return (
        <div style={{ marginTop: '10rem' }}>
            <div className="text-center">
                <h2>Your payment is successful</h2>
                <p>Go to cart and place your order</p>
                <br />
                <Link to="/" className="subscribe-button">
                    <span style={{ color: 'black', fontWeight: 'bold' }}>Go To Dashboard</span>
                </Link>
            </div>
        </div>
    );
};

export default PaymentSuccess;
